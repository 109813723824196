.nav-header {
  background-color: $white;
  width: 100%;
  display: block;
  user-select: none;
  border-bottom: 4px solid $purple;
}

.nav-header-menu-wrapper {
  height: inherit;
  margin: 0 auto;
  max-width: $max-layout-width;
  padding: 0 14px 0 0;

  @media (max-width: $max-layout-width) {
    padding: 0 10px;
  }
}

.nav-header-full {
  height: $header-height;
}
